.App {
  text-align: center;
}

.content {
  position: absolute;
  padding-left: 18vh;
  bottom: 0px;
  left: 0px;
  text-align: left;
  font-size: 1.5vh;
}

.heading {
  line-height: 0px;
}

.App-logo {
  height: 17vh;
  position: absolute;
  bottom: 0px;
  left: 0vh;
}
@media only screen and (max-width: 600px) {
  .App-logo {
      height: 17vh;
      left: 0vh;
  }
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #ffffff;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2vh;
  color: rgb(80, 79, 79);
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
